import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Projetos.css";

const projetos = [
  { name: "VisEdu", link: "/assets/VisEdu.pdf" },
  { name: "FinTech Revolutions", link: "/assets/FinTechRevolutions.pdf" },
  { name: "Educa Fácil", link: "/assets/EducaFácil.pdf" },
  { name: "Hosptech", link: "/assets/HospTec.pdf" },
  { name: "Nation Power Ray", link: "/assets/NATION POWER RAY.pdf" },
  { name: "Educa Quality", link: "/assets/EducaQuality.pdf" },
  { name: "Patinhas do bem", link: "/assets/Patinhas_do_bem.pdf" },
  { name: "Home Help Tech", link: "/assets/HomeHelpTech.pdf" },
  { name: "Desapega TDAH", link: "/assets/Desapega_TDAH.pdf" },
  { name: "Pegada do Trabalhador", link: "/assets/PEGADA-DO-TRABALHADOR.pdf" },
  { name: "Cura Connect", link: "/assets/Cura_Connect.pdf" },
  { name: "Green Box", link: "/assets/GREENBOX.pdf" },
  { name: "Neuro-Class", link: "/assets/Neuro-Class.pdf" },
  { name: "Comer Bem", link: "/assets/Comer_Bem.pdf" },
  { name: "Planeta verde on-line", link: "/assets/Planeta_verde_online.pdf" },
  { name: "Entregue já", link: "/assets/Entreguejá .pdf" },
  { name: "Planta Ativa", link: "/assets/Planta_Ativa.pdf" },
  { name: "Wide Planet", link: "/assets/WidePlanet.pdf" },
  { name: "MEDFIC", link: "/assets/WidePlanet.pdf" },
];

const Projetos = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Inicializa o AOS com uma duração padrão de 1000ms
  }, []);

  return (
    <section className="projetos">
      <h3 data-aos="fade-up">Projetos 2024</h3>
      <div className="projetos-list">
        {projetos.map((projeto, index) => (
          <a
            key={index}
            href={projeto.link}
            download
            className="projeto-link"
            data-aos="fade-up"
            data-aos-delay={index * 100} // Atraso incremental para cada item, criando um efeito em cascata
          >
            {projeto.name}
          </a>
        ))}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="31"
          viewBox="0 0 35 31"
          fill="none"
          className="primeiro"
          data-aos="fade-right"
        >
          <path
            d="M0 0.638428V30.1641H35V0.638428H0ZM30.1272 26.0534H4.88183V4.74911H30.1361L30.1272 26.0534Z"
            fill="#FF9D00"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          className="segundo"
          data-aos="fade-left"
        >
          <path
            d="M0 0.979492V15.3205H17V0.979492H0ZM14.6332 13.3239H2.37118V2.97611H14.6376L14.6332 13.3239Z"
            fill="#EC008B"
          />
        </svg>
      </div>
    </section>
  );
};

export default Projetos;
