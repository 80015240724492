import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Localizacao.css";

const Localizacao = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <section className="localizacao">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="49"
        height="22"
        viewBox="0 0 49 22"
        fill="none"
        className="primeiro"
        data-aos="fade-down"
      >
        <path d="M3.8147e-06 0.146095H49L24.5 21.2358L3.8147e-06 0.146095Z" fill="#EA3351" />
      </svg>
      <h3 data-aos="fade-up">Localização</h3>
      <p id="txtLocalizacao" data-aos="fade-up" data-aos-delay="100">
        Nossa feira vai acontecer no Colégio Estadual Dr. Gastão Vidigal
      </p>
      <p data-aos="fade-up" data-aos-delay="200">
        Quinta, 31 de outubro de 2024 <br /> e <br /> Sexta, 01 de novembro de 2024
      </p>
      <div className="map-container" data-aos="zoom-in" data-aos-delay="300">
        <iframe
          title="Localização"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1539.4005636381316!2d-51.935745459681876!3d-23.41236378577218!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ecd12b19d66a27%3A0x89fe5eb0bbf8edac!2sCol%C3%A9gio%20Estadual%20Dr.%20Gast%C3%A3o%20Vidigal!5e0!3m2!1spt-BR!2sbr!4v1728922625655!5m2!1spt-BR!2sbr"
          width="800"
          height="450"
          style={{ border: 0, borderRadius: "50px" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </section>
  );
};

export default Localizacao;
