import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import workshopImage1 from "../../assets/leo-semfundo.png";
import workshopImage2 from "../../assets/pietro-semfundo.png";
import "./Workshops.css";
import { React } from "react";

const Workshops = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 })
  }, []);

  return (
    <section className="workshops">
      <h3 className="titulo" data-aos="fade-up">Workshops</h3>
      <div className="workshop-list">
        <div className="workshop-content" data-aos="fade-right">
          <div className="workshop">
            <h4>Flutter</h4>
            <p>Nessa aula, aprenda a construir uma lista de supermercado usando uma das
              ferramentas mais promissoras do mercado para quem quer desenvolver sem código: Flutterflow.</p>
          </div>
          <img src={workshopImage1} alt="Workshop 1" className="workshop-image" data-aos="zoom-in" />
        </div>
        <div className="workshop-content" data-aos="fade-left">
          <img src={workshopImage2} alt="Workshop 2" className="workshop-image" data-aos="zoom-in" />
          <div className="workshop">
            <h4>Tinkercad</h4>
            <p>Venha explorar o design 3D e eletrônica no workshop sobre Tinkercad. Transforme suas ideias em realidade com projetos 3D!</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Workshops;
