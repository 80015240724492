import React from "react";
import "./Footer.css";

const Footer = () => (
  <footer className="footer">
    <p id="txtLegendaFooter">Para informações sobre nosso evento siga nossas redes sociais</p>
    <svg xmlns="http://www.w3.org/2000/svg" width="99" height="5" viewBox="0 0 99 5" fill="none" className="primeiro">
      <path d="M82.23 0L65.79 3.62314L49.34 0L32.9 3.62314L16.46 0L0 3.62978L1.88995 4.01513L16.46 0.801696L32.9 4.42483L49.34 0.801696L65.79 4.42483L82.23 0.801696L96.74 3.99741L98.55 3.59435L82.23 0Z" fill="#EC008B" />
    </svg>
    <a href="http://www.mgagastaovidigal.seed.pr.gov.br/modules/noticias/" target="_blank" rel="noopener noreferrer"> Gastão Vidigal</a>
    <a>Farmácia</a>
    <a>Enfermagem</a>
    <a href="https://www.instagram.com/gastao.tds?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" rel="noopener noreferrer">Desenvolvimento de Sistemas</a>
  </footer>
);

export default Footer;