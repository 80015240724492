import React from "react";
import logo from "../../assets/logo-amarela.png";
import "./Header.css";

const Header = () => (
  <header className="header">
    <img src={logo} alt="Logo EGV" className="logo" />
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="31" viewBox="0 0 35 31" fill="none" className="primeiro">
      <path d="M0 0.638428V30.1641H35V0.638428H0ZM30.1272 26.0534H4.88183V4.74911H30.1361L30.1272 26.0534Z" fill="#FF9D00" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" className="segundo">
      <path d="M0 0.979492V15.3205H17V0.979492H0ZM14.6332 13.3239H2.37118V2.97611H14.6376L14.6332 13.3239Z" fill="#EC008B" />
    </svg>
  </header>
);

export default Header;