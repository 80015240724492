import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import feiraImage from "../../assets/map.png";
import feiraImageSecondary from "../../assets/logo-imagem.png";
import "./SobreFeira.css";

const SobreFeira = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <section className="sobre-feira">
      <div className="feira-images" data-aos="fade-up">
        <img src={feiraImage} alt="Imagem da Feira Principal" className="feira-image" data-aos="zoom-in" />
        <img src={feiraImageSecondary} alt="Imagem Secundária" className="feira-image" data-aos="zoom-in" data-aos-delay="200" />
      </div>
      <div className="feira-info" data-aos="fade-up">
        <h3 data-aos="fade-right">Sobre a feira</h3>
        <p data-aos="fade-up" data-aos-delay="100">
          Nos dias 31 e 01, ocorrerão dois dias de apresentações dos trabalhos realizados pelos 
          alunos dos cursos técnicos integrados ao novo ensino médio: Desenvolvimento de Sistemas, 
          Enfermagem e Farmácia. Será uma excelente oportunidade para conferir de perto os projetos 
          desenvolvidos e o impacto do aprendizado prático.
        </p>
        <p data-aos="fade-up" data-aos-delay="200">Colégio Gastão Vidigal, Maringá - PR</p>
        <div className="feira-details" data-aos="fade-up" data-aos-delay="300">
          <div className="feira-detail" data-aos="zoom-in" data-aos-delay="400">
            <span>2</span>
            <p>Dias de feira</p>
          </div>
          <div className="feira-detail" data-aos="zoom-in" data-aos-delay="500">
            <span>20+</span>
            <p>Projetos</p>
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="21"
          viewBox="0 0 24 21"
          fill="none"
          className="primeiro"
          data-aos="fade-left"
          data-aos-delay="600"
        >
          <path d="M23.17 0.743591H0V20.3149H23.17V0.743591Z" fill="#EC008B" />
        </svg>
      </div>
    </section>
  );
};

export default SobreFeira;
