import React from "react";
import "./Carousel.css";
import { Carousel as ReactCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import image1 from "../../assets/foto-aluno01.jpg";
import image2 from "../../assets/foto-aluno02.jpg";
import image3 from "../../assets/foto-aluno03.jpg";
import image4 from "../../assets/foto-aluno04.jpg";
import image5 from "../../assets/foto-aluno05.jpg";
import image6 from "../../assets/foto-aluno06.jpg";
import image7 from "../../assets/foto-aluno07.jpg";
import image8 from "../../assets/foto-aluno08.jpg";
import image9 from "../../assets/foto-aluno09.jpg";
import image10 from "../../assets/foto-aluno10.jpg";
import image11 from "../../assets/foto-aluno11.jpg";
import image12 from "../../assets/foto-aluno12.jpg";
import image13 from "../../assets/foto-aluno13.jpg";


const Carousel = () => (
    <section className="carousel-section">
        <div className="carousel-content">
            <div className="carousel-wrapper">
                <ReactCarousel autoPlay infiniteLoop showThumbs={false} showStatus={false} interval={3000} dynamicHeight={true} swipeable={true} className="carrosel">
                    <div>
                        <img src={image13} alt="Imagem 13" className="carousel-image" />
                        <p className="legend">HospTech</p>
                    </div>
                    <div>
                        <img src={image2} alt="Imagem 2" className="carousel-image" />
                        <p className="legend">Educa Quality</p>
                    </div>
                    <div>
                        <img src={image3} alt="Imagem 3" className="carousel-image" />
                        <p className="legend">Fin Tech Revolutions</p>
                    </div>
                    <div>
                        <img src={image4} alt="Imagem 4" className="carousel-image" />
                        <p className="legend">Power Nation Ray</p>
                    </div>
                    <div>
                        <img src={image5} alt="Imagem 5" className="carousel-image" />
                        <p className="legend">Glob Tech</p>
                    </div>
                    <div>
                        <img src={image6} alt="Imagem 6" className="carousel-image" />
                        <p className="legend">Planeta Verde On-line</p>
                    </div>
                    <div>
                        <img src={image7} alt="Imagem 7" className="carousel-image" />
                        <p className="legend">Cura Connect</p>
                    </div>
                    <div>
                        <img src={image8} alt="Imagem 8" className="carousel-image" />
                        <p className="legend">Home Help Tech</p>
                    </div>
                    <div>
                        <img src={image9} alt="Imagem 9" className="carousel-image" />
                        <p className="legend">Green Box</p>
                    </div>
                    <div>
                        <img src={image10} alt="Imagem 10" className="carousel-image" />
                        <p className="legend">Pegada do Trabalhador</p>
                    </div>
                    <div>
                        <img src={image11} alt="Imagem 11" className="carousel-image" />
                        <p className="legend">Patinhas do Bem</p>
                    </div>
                    <div>
                        <img src={image12} alt="Imagem 12" className="carousel-image" />
                        <p className="legend">Medfic</p>
                    </div>
                    <div>
                        <img src={image1} alt="Imagem 1" className="carousel-image" />
                        <p className="legend">Entrega Já</p>
                    </div>
                </ReactCarousel>
            </div>
            <div className="carousel-header">
                <h2>
                    <img src={require("../../assets/map.png")} alt="EGV Logo" className="egv-logo" />
                    2024
                </h2>
                <p>Exposição dos cursos técnicos do Gastão Vidigal</p>
            </div>
        </div>
    </section>
);

export default Carousel;


