import React from "react";
import Header from "./components/Header/Header";
import Carousel from "./components/Carousel/Carousel";
import SobreFeira from "./components/SobreFeira/SobreFeira";
import Workshops from "./components/Workshops/Workshops";
import Projetos from "./components/Projetos/Projetos";
import Localizacao from "./components/Localizacao/Localizacao";
import Footer from "./components/Footer/Footer";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Header />
      <Carousel />
      <svg xmlns="http://www.w3.org/2000/svg" width="99" height="5" viewBox="0 0 99 5" fill="none" className="terceiro-app">
        <path d="M82.23 0L65.79 3.62314L49.34 0L32.9 3.62314L16.46 0L0 3.62978L1.88995 4.01513L16.46 0.801696L32.9 4.42483L49.34 0.801696L65.79 4.42483L82.23 0.801696L96.74 3.99741L98.55 3.59435L82.23 0Z" fill="#EC008B" />
      </svg>
      <SobreFeira />
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none" className="segundo-app">
        <path d="M8.74002 0.730713C7.01141 0.730713 5.32165 1.14844 3.88437 1.93108C2.44708 2.71371 1.32682 3.8261 0.665311 5.12757C0.00380117 6.42905 -0.169299 7.86115 0.167936 9.24279C0.50517 10.6244 1.33759 11.8935 2.5599 12.8896C3.78221 13.8857 5.33955 14.5641 7.03494 14.8389C8.73033 15.1138 10.4877 14.9727 12.0847 14.4336C13.6817 13.8945 15.0467 12.9816 16.007 11.8103C16.9674 10.639 17.48 9.26195 17.48 7.85325C17.48 6.9179 17.254 5.99172 16.8147 5.12757C16.3755 4.26343 15.7317 3.47824 14.9201 2.81686C14.1085 2.15547 13.1451 1.63082 12.0847 1.27288C11.0243 0.914943 9.88777 0.730713 8.74002 0.730713ZM8.74002 11.2434C7.91484 11.2434 7.10824 11.0439 6.42227 10.6701C5.7363 10.2963 5.20182 9.7651 4.8865 9.14367C4.57118 8.52224 4.48919 7.83855 4.65091 7.17912C4.81262 6.5197 5.21076 5.9142 5.79495 5.43927C6.37914 4.96434 7.12309 4.64133 7.93264 4.51112C8.7422 4.38092 9.58095 4.44938 10.3427 4.70784C11.1045 4.9663 11.7551 5.40313 12.2121 5.96305C12.6691 6.52296 12.912 7.18079 12.91 7.85325C12.8995 8.74887 12.4555 9.60488 11.6746 10.2351C10.8937 10.8654 9.83908 11.219 8.74002 11.2189V11.2434Z" fill="#FFC200" />
      </svg>
      <Workshops />
      <svg xmlns="http://www.w3.org/2000/svg" width="39" height="32" viewBox="0 0 39 32" fill="none" className="primeiro-app">
        <path d="M0 0.0746765V31.8001H38.93V0.0746765H0ZM33.51 27.3832H5.42999V4.49163H33.52L33.51 27.3832Z" fill="#FF9D00" />
      </svg>
      <Projetos />
      <Localizacao />
      <Footer />
    </div>
  );
}

export default App;